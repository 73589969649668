import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useEffect, useState } from "react";
import UpdateCostCenterPositionItemInput from "../../../components/CostCenters/UpdateCostCenterPositionItemInput";
import { useFeedBack } from "../../../context/FeedBackContext";
import CurrencyFormat from "react-currency-format";
import { Button, Modal } from "react-bootstrap";
import clsx from "clsx";
import { monthNames } from "../../../util/consts";

const CostCentersPositionsItemsUpdate2 = () => {

    const { id, positionId } = useParams();

    const { setCurrentTitle, setCustomAlert } = useFeedBack();

    const [currentValue, setCurrentValue] = useState({
        value: 0,
        top: '',
        max: '',
        applyIpc: 'no',
        factorId: '',
        applyMonths: [],
        applyIpcMonths: [],
        type: 'variable'
    });

    const [show, setShow] = useState(false);

    const [filters, setFilters] = useState({
        itemSearch: '',
        orderBy: 'description,ASC'
    })

    const [{ data: factorsResponse }, getFactors] = useAxios({ url: `/factors`, params: { perPage: 1000 } }, { useCache: false });

    const [{ data, loading }, getItems] = useAxios({ url: `/cost-centers/${id}/positions/${positionId}/itemsV2`, params: filters }, { useCache: false });

    const [{ }, removeItem] = useAxios({ method: 'DELETE' }, { useCache: false, manual: true });

    const [{ loading: loadingUpdate, error }, updateCurrentItem] = useAxios({ url: `/cost-centers/${id}/positions/${positionId}/items`, method: 'PUT' }, { useCache: false, manual: true });

    useEffect(() => {
        if (data) setCurrentTitle({
            subTitle: 'Configurar cargo',
            title: `${data?.positionName}`
        });
    }, [data])

    const handleChange = (e) => {
        var dataToSet = {
            ...currentValue
        };

        if (e.target.type === 'checkbox') {
            if (dataToSet[e.target.name].includes(Number(e.target.value))) {
                dataToSet[e.target.name] = dataToSet[e.target.name].filter(value => value !== Number(e.target.value));
            } else {
                dataToSet[e.target.name] = [...dataToSet[e.target.name], Number(e.target.value)];
            }
        } else {
            dataToSet[e.target.name] = e.target.value
        }

        setCurrentValue(dataToSet);
    }

    const handleUpdate = () => {
        updateCurrentItem({ data: currentValue }).then((response) => {
            setCustomAlert({
                show: true,
                message: "El registro ha sido actualizado exitosamente.",
                severity: "success",
                title: "Operación Exitosa",
            });
            setShow(false);
            getItems();
        }).catch((error) => {

        });
    }

    const handleEdit = (positionItem) => {
        setCurrentValue((oldValue) => {
            return {
                itemCode: positionItem?.item_code,
                value: positionItem?.value || '',
                top: positionItem?.top || '',
                max: positionItem?.max || '',
                applyIpc: positionItem?.apply_ipc,
                factorId: positionItem?.factor_id,
                applyMonths: positionItem?.months?.map(month => Number(month?.id)),
                applyIpcMonths: positionItem?.ipc_months?.map(month => Number(month?.id)),
                type: positionItem?.type
            }
        });

        setShow(true);
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="row w-100 align-items-center">
                        <div className="col-8">
                            Valores de haberes para:
                            <h4>
                                {data?.positionName}
                            </h4>
                        </div>
                        <div className="col-4">
                            <input
                                type="text"
                                placeholder="Buscar haberes..."
                                className="form-control"
                                onChange={(e) => setFilters({ itemSearch: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <ul className="custom-scrollbar scrollbar-primary">
                        {
                            data?.items?.map((positionItem, i) => {
                                return (
                                    <li key={i} className="border-bottom mb-3">
                                        <div className="row align-items-center">
                                            <div className="col-md-4">
                                                <div>
                                                    <b>{positionItem?.item?.code}</b>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm text-primary fw-bold"
                                                        onClick={() => handleEdit(positionItem)}
                                                    >
                                                        Editar
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm text-danger fw-bold"
                                                        onClick={() => removeItem({ url: `/cost-centers/remove-position-item/${positionItem?.id}` }).then(() => getItems())}
                                                    >
                                                        Eliminar
                                                    </button>
                                                </div>
                                                {positionItem?.item?.description}
                                            </div>
                                            <div className="col-md-8">
                                                <div className="row">
                                                    <div className="col-md-4 mb-4">
                                                        <b className="text-primary d-block">
                                                            Valor:
                                                        </b>
                                                        {positionItem?.value}
                                                    </div>
                                                    <div className="col-md-4 mb-4">
                                                        <b className="text-primary d-block">
                                                            Valor de alerta:
                                                        </b>
                                                        {positionItem?.max || '--'}
                                                    </div>
                                                    <div className="col-md-4 mb-4">
                                                        <b className="text-primary d-block">
                                                            Tope maximo:
                                                        </b>
                                                        {positionItem?.top || '--'}
                                                    </div>
                                                    <div className="col-md-4 mb-4">
                                                        <b className="text-primary d-block">
                                                            Factor:
                                                        </b>
                                                        {positionItem?.factor?.factor || '--'}
                                                    </div>
                                                    <div className="col-md-4 mb-4">
                                                        <b className="text-primary d-block">
                                                            Aplica IPC:
                                                        </b>
                                                        {positionItem?.apply_ipc}
                                                    </div>
                                                    <div className="col-md-4 mb-4">
                                                        <b className="text-primary d-block">
                                                            Tipo de haber:
                                                        </b>
                                                        {positionItem?.type}
                                                    </div>
                                                    <div className="col-md-12">
                                                        <b className="text-primary d-block">
                                                            Meses en los que se aplica el haber
                                                        </b>
                                                        <div className="row">
                                                            {
                                                                positionItem?.months?.length > 0 ?
                                                                    positionItem?.months?.map((month) => {
                                                                        return (
                                                                            <div className="col-md-2">
                                                                                {month?.name}
                                                                            </div>
                                                                        )
                                                                    })
                                                                    :
                                                                    <div className="col-md-12 mb-3">
                                                                        Todos los meses.
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <b className="text-primary d-block">
                                                            Meses en los que se recalcula por IPC el haber
                                                        </b>
                                                        {
                                                            positionItem?.apply_ipc === 'si' ?
                                                                <div className="row">
                                                                    {
                                                                        positionItem?.ipc_months?.length > 0 ?
                                                                            positionItem?.ipc_months?.map((month) => {
                                                                                return (
                                                                                    <div className="col-md-2">
                                                                                        {month?.name}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            <div className="col-md-12 mb-3">
                                                                                Todos los meses
                                                                            </div>
                                                                    }
                                                                </div>
                                                                :
                                                                'No se recalcula por IPC.'
                                                        }
                                                    </div>
                                                </div>
                                                <p>

                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                        {
                            !data?.items?.length &&
                            <li className="text-center">
                                <h4 className="text-danger">
                                    No se encontrarón resultados.
                                </h4>
                            </li>
                        }
                        <li className="text-center">
                            <button className="btn btn-primary" onClick={() => setShow(true)}>
                                Agregar haber
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Agregar el haber</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="mb-3 col-md-6">
                            <label htmlFor="" className="text-primary">
                                Código del haber:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={currentValue?.itemCode}
                                placeholder="Código del haber"
                                onChange={handleChange}
                                name="itemCode"
                            />
                            <small>Sin ingresas un haber ya creado. El sistema reemplazará los valores anteriores por los nuevos.</small>
                        </div>
                        <div className="mb-3 col-md-6">
                            <label htmlFor="" className="text-primary">
                                Valor:
                            </label>
                            <CurrencyFormat
                                className="form-control"
                                style={{ minWidth: 100, display: 'block' }}
                                value={currentValue?.value}
                                isNumericString
                                decimalSeparator=","
                                thousandSeparator="."
                                onValueChange={(e) => handleChange({ target: { name: 'value', value: e.value } })}
                                placeholder="Por favor ingrese el valor"
                            />
                        </div>
                        <div className="mb-3 col-md-6">
                            <label htmlFor="" className="text-primary">
                                Valor Alerta:
                            </label>
                            <CurrencyFormat
                                className="form-control"
                                style={{ minWidth: 100, display: 'block' }}
                                value={currentValue?.max}
                                isNumericString
                                decimalSeparator=","
                                thousandSeparator="."
                                onValueChange={(e) => handleChange({ target: { name: 'max', value: e.value } })}
                                placeholder="Por favor ingrese el valor"
                            />
                            <small>
                                El sistema alertará al usuario cuando se pase de este valor. y tendra que ingresar una nota obligatoriamente. Al dejarlo vacio el sistema no le pedira nota al usuario.
                            </small>
                        </div>
                        <div className="mb-3 col-md-6">
                            <label htmlFor="" className="text-primary">
                                Tope maximo:
                            </label>
                            <CurrencyFormat
                                className="form-control"
                                style={{ minWidth: 100, display: 'block' }}
                                value={currentValue?.top}
                                isNumericString
                                decimalSeparator=","
                                thousandSeparator="."
                                onValueChange={(e) => handleChange({ target: { name: 'top', value: e.value } })}
                                placeholder="Por favor ingrese el valor"
                            />
                            <small>
                                Valor maximo que puede ingresar el usuario. Si se deja nulo el usuario podrá ingresar el valor que quiera.
                            </small>
                        </div>
                        <div className="mb-3 col-md-12">
                            <label htmlFor="" className="text-primary">
                                Factor:
                            </label>
                            <select name="factorId" className="form-control" value={currentValue?.factorId} onChange={handleChange}>
                                <option value="">Factor...</option>
                                {
                                    factorsResponse?.data?.map(factor => {
                                        return (
                                            <option value={factor?.id}>
                                                {factor?.factor}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="mb-3 col-md-12">
                            <div className="form-group mb-3">
                                <p className="text-primary mb-0">
                                    Configurar tipo de haber
                                </p>
                                <select name="type" value={currentValue?.type} className="form-control" onChange={handleChange}>
                                    <option value="variable">Variable</option>
                                    <option value="fijo">Fijo</option>
                                    <option value="bloqueado">Bloqueado</option>
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 col-md-12">
                            <p className="text-primary mb-0">
                                Aplicar haber solo los meses de:
                            </p>
                            <small className="d-block">Al dejar las casillas en blanco el sistema tomará todos los meses por defecto.</small>
                            <br />
                            <div className="row align-items-center">
                                {
                                    monthNames?.map((month, key) => {
                                        return (
                                            <label className="mb-3 mb-md-1 col-md-3 d-flex" style={{ cursor: 'pointer' }}>
                                                {month?.name}
                                                <input
                                                    checked={currentValue?.applyMonths?.includes(key + 1)}
                                                    type="checkbox"
                                                    onChange={handleChange}
                                                    value={key + 1}
                                                    name="applyMonths"
                                                    className="ml-auto"
                                                    style={{ marginLeft: 'auto' }}
                                                />
                                            </label>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="mb-3 col-md-12">
                            <div className="form-group">
                                <p className="text-primary mb-0">
                                    ¿Aplica IPC?
                                </p>
                                <select name="applyIpc" value={currentValue?.applyIpc} className="form-control" onChange={handleChange}>
                                    <option value="no">no</option>
                                    <option value="si">si</option>
                                </select>
                            </div>
                        </div>
                        {
                            currentValue?.applyIpc === 'si' &&
                            <div className="mb-3 col-md-12">
                                <div className="animate__animated animate__fadeInUp">
                                    <p className="text-primary mb-0">
                                        Aplicar IPC en los meses:
                                    </p>
                                    <small className="d-block">Al dejar las casillas en blanco el sistema tomará todos los meses por defecto.</small>
                                    <br />
                                    <div className="row align-items-center">
                                        {
                                            monthNames?.map((month, key) => {
                                                return (
                                                    <label className="mb-3 mb-md-0 col-md-3 d-flex">
                                                        {
                                                            month?.name
                                                        }
                                                        <input
                                                            checked={currentValue?.applyIpcMonths?.includes(key + 1)}
                                                            type="checkbox"
                                                            value={key + 1}
                                                            name="applyIpcMonths"
                                                            className="ml-auto"
                                                            onChange={handleChange}
                                                            style={{ marginLeft: 'auto' }}
                                                        />
                                                    </label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Cancelar
                    </Button>
                    <Button disabled={loadingUpdate} variant="primary" onClick={handleUpdate}>
                        {
                            loadingUpdate ?
                                <div className="spinner" style={{ width: 20, height: 20 }}>
                                    <div className="double-bounce1 bg-light"></div>
                                    <div className="double-bounce2 bg-light"></div>
                                </div>
                                :
                                'Agregar Haber'
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CostCentersPositionsItemsUpdate2;