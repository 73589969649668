export const statuses = [
    {
        id: 1,
        name: 'Pendiente',
        pluralName: 'Pendientes',
        color: '#f0e51d',
        variantColor: "warning"
    },
    {
        id: 5,
        name: 'Rechazado',
        pluralName: 'Rechazados',
        color: '#ed1a36',
        variantColor: "danger"
    },
    {
        id: 3,
        name: 'Procesando',
        pluralName: 'Procesando',
        color: '#1362FC',
        variantColor: "primary"
    },
    {
        id: 4,
        name: 'Finalizado',
        pluralName: 'Finalizados',
        color: '#00D487',
        variantColor: "success"
    },
    {
        id: 2,
        name: 'Anulado',
        pluralName: 'Anulados',
        color: '#FF0000',
        variantColor: "danger"
    },
];

export const weekDaysNames = [
    {
        name: 'Domingo'
    },
    {
        name: 'Lunes'
    },
    {
        name: 'Martes'
    },
    {
        name: 'Miércoles'
    },

    {
        name: 'Jueves'
    },

    {
        name: 'Viernes'
    },

    {
        name: 'Sábado'
    }
];

export const monthNames = [
    {
        name: 'Enero'
    },
    {
        name: 'Febrero'
    },
    {
        name: 'Marzo'
    },
    {
        name: 'Abril'
    },

    {
        name: 'Mayo'
    },

    {
        name: 'Junio'
    },

    {
        name: 'Julio'
    },

    {
        name: 'Agosto'
    },

    {
        name: 'Septiembre'
    },

    {
        name: 'Octubre'
    },

    {
        name: 'Noviembre'
    },

    {
        name: 'Diciembre'
    }
];

export const periodTypeDescription = {
    'M': 'Mensual',
    'Q': 'Quincenal',
    'S': 'Semanal',
    'E': 'Especial'
}

export const periodTypeValues = {
    'M': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    'Q': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
    'S': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52],
    'E': ''
}