import { Dropdown } from "react-bootstrap"
import { FaEllipsisH } from "react-icons/fa";
import useAxios from "../../hooks/useAxios";
import { useEffect } from "react";
import swal from "sweetalert";


const PayrrolsGlobalActions = ({ selectedValues, endPoint, getRecords }) => {

    const [{ data: statusesResponse, loading: loadingStatuses }, getStatuses] = useAxios({ url: `/statuses` }, { useCache: false });

    const [{ data: updateStatusesResponse, loading: loadingUpdateStatuses }, updateStatuses] = useAxios({ url: `/${endPoint}/mark-has-reviewed`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ loading: loadingOpen }, openPayrrols] = useAxios({ url: `/${endPoint}/mark-has-open`, method: 'PUT' }, { manual: true, useCache: false });

    const handleOpenPayrrols = () => {

        swal({
            title: "¿Quieres abrir los procesos seleccionados?",
            text: `Solo se abriran los procesos que se encuentren en el estado BLOQUEADO.`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                openPayrrols({
                    data: {
                        payrrolIds: selectedValues,
                    }
                }).then(() => getRecords());
            }
        })
    }


    const handleUpdateStatutes = () => {
        updateStatuses({
            data: {
                payrrolIds: selectedValues,
            }
        }).then(() => getRecords());
    }

    return (
        <div className="text-end">
            <h5>Acciones globales:</h5>
            {
                selectedValues?.length > 0 ?
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle variant="" size="sm">
                            <FaEllipsisH style={{ fontSize: 20 }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ maxHeight: '50vh', overflowY: 'auto' }} className="custom-scrollbar scrollbar-primary">
                            <Dropdown.Header>Cambiar estado:</Dropdown.Header>
                            <Dropdown.Item title="IMPORTANTE: todas las nominas seleccionadas deben estar aprobadas." autoClose={false} onClick={handleUpdateStatutes} className={`text-success`} >
                                Revisado
                            </Dropdown.Item>
                            <Dropdown.Item title="IMPORTANTE: Solo las nominas en status bloqueado serán abiertas." autoClose={false} onClick={handleOpenPayrrols} className={`text-primary`} >
                                {
                                    loadingOpen ?
                                        'Cargando...'
                                        :
                                        'Abrir Procesos'
                                }
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    :
                    <span>Debes seleccionar al menos un registro.</span>
            }
        </div>
    )
}

export default PayrrolsGlobalActions