import useAxios from "../../hooks/useAxios";
import swal from "sweetalert";

const ChangePayrrolStatusComponent = ({ payrrol, onChangeStatus }) => {

    const [{ loading, error }, updateStatus] = useAxios({ url: `/payrrols/${payrrol?.id}/status`, method: 'PUT' }, { manual: true, useCache: false });

    const handleUpdate = async (statusId) => {

        swal({
            title: "¿Estas Seguro(a)?",
            text: "¿Quieres cambiar el estado?",
            icon: "warning",
            buttons: true
        }).then(async (willDelete) => {
            if (willDelete) {
                try {
                    const response = await updateStatus({ data: { statusId } });
                    onChangeStatus(response?.data?.data);
                } catch (error) {

                }
            } else {

            }
        });
    }

    return (
        <>
            <div className="text-center">
                Cambiar estado a:
            </div>
            <div className="d-flex align-items-center justify-content-center my-3">
                {
                    loading ?
                        <div className="spinner">
                            <div className="double-bounce1 bg-primary"></div>
                            <div className="double-bounce2 bg-primary"></div>
                        </div>
                        :
                        payrrol?.availableStatuses?.map((status, i) => {
                            return (
                                <button onClick={() => handleUpdate(status?.id)} key={i} className={`btn btn-${status?.variantColor} btn-xs mx-2`}>
                                    {status?.name}
                                </button>
                            )
                        })
                }
            </div>
        </>
    )
}

export default ChangePayrrolStatusComponent;