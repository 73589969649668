import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useEffect, useState } from "react";
import UpdateCostCenterPositionItemInput from "../../../components/CostCenters/UpdateCostCenterPositionItemInput";
import { useFeedBack } from "../../../context/FeedBackContext";

const CostCentersPositionsItemsUpdate = () => {

    const { id, positionId } = useParams();

    const { setCurrentTitle } = useFeedBack();

    const [filters, setFilters] = useState({
        itemSearch: '',
        orderBy: 'description,ASC'
    })

    const [{ data: factorsResponse }, getFactors] = useAxios({ url: `/factors`, params: { perPage: 1000 } }, { useCache: false });

    const [{ data, loading }, getItems] = useAxios({ url: `/cost-centers/${id}/positions/${positionId}/items`, params: filters }, { useCache: false });

    useEffect(() => {
        if (data) setCurrentTitle({
            subTitle: 'Configurar cargo',
            title: `${data?.positionName}`
        });
    }, [data])

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="row w-100 align-items-center">
                        <div className="col-8">
                            Valores de haberes para:
                            <h4>
                                {data?.positionName}
                            </h4>
                        </div>
                        <div className="col-4">
                            <input
                                type="text"
                                placeholder="Buscar haberes..."
                                className="form-control"
                                onChange={(e) => setFilters({ itemSearch: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <ul className="custom-scrollbar scrollbar-primary">
                        {
                            !data?.items?.length &&
                            <li className="text-center">
                                <h4>
                                    No se encontrarón resultados.
                                </h4>
                            </li>

                        }
                        {data?.items?.map((item, i) => {
                            return (
                                <li key={i} className="p-3 border-bottom">
                                    <div className="row align-items-center">
                                        <div className="col-md-3 mb-3 mb-md-0">
                                            <p className="text-primary mb-0">
                                                {item?.code}
                                            </p>
                                            <p className="mb-0">
                                                {item?.description}
                                            </p>
                                        </div>
                                        <div className="col-md-9 mb-3 mb-md-0">
                                            <UpdateCostCenterPositionItemInput
                                                factors={factorsResponse?.data}
                                                costCenterId={id}
                                                defaultValue={item?.value}
                                                itemCode={item?.code}
                                                positionId={positionId}
                                                url={`/cost-centers/${id}/positions/${positionId}/items`}
                                            />
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CostCentersPositionsItemsUpdate;