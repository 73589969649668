import { dateFine } from "../../../util/Utilities";
import DateFormatter from "../../DateFormatter";
import UsersActionDropdown from "../../Users/UsersActionDropdown";
import TableCheck from "../TableCheck";

const UserColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'Id',
        accessor: 'id'
    },
    {
        Label: () => 'Nombre',
        Component: ({ value }) => <p style={{ textTransform: 'uppercase' }}> {value?.name || '--'} </p>
    },
    {
        Label: () => 'Cargo',
        Component: ({ value }) => <p style={{ textTransform: 'uppercase' }}> {value?.position?.name || '--'} </p>
    },
    {
        Label: () => 'Centro de costo',
        Component: ({ value }) => <p style={{ textTransform: 'uppercase' }}> {value?.costCenter?.name || '--'} </p>
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => value?.createdAt && <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    },
    {
        Label: () => 'Acciones',
        Component: UsersActionDropdown
    }
];

export default UserColumns;

