import { useParams } from "react-router-dom"
import useAxios from "../../../hooks/useAxios";
import { FaPlus, FaRegUser, FaProjectDiagram } from "react-icons/fa";
import { useEffect, useState } from "react";
import profileImg from '../../../images/profile.png';
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useFeedBack } from "../../../context/FeedBackContext";
import { monthNames, periodTypeDescription } from "../../../util/consts";
import RenderStatus from "../../../components/RenderStatus";
import ChangePayrrolStatusComponent from "../../../components/Payrrols/ChangePayrrolStatusComponent";
import CustomTable from "../../../components/CustomTable/CustomTable";
import CostCenterPayrrolColumns from "../../../components/CustomTable/Columns/CostCenterPayrrolColumns";



const CostCenterManagement = () => {

    const { setLoading } = useFeedBack();

    const { id } = useParams();

    const [currentWorkers, setCurrentWorkers] = useState([]);

    const [filters, setFilters] = useState({
        search: '',
        page: 1
    });

    const [payrrolFilters, setPayrrolFilters] = useState({
        page: 1,
        notValidate: 'si'
    });

    const [{ data, loading }] = useAxios({ url: `/cost-centers/${id}` }, { useCache: false });

    const [{ data: workersResponse, loading: loadingWorkers }] = useAxios({ url: `/cost-centers/${id}/workers`, params: filters }, { useCache: false });

    const [{ data: payrrolsResponse, loading: payrrolsLoading }] = useAxios({ url: `/cost-centers/${id}/payrrols`, params: payrrolFilters }, { useCache: false });

    useEffect(() => {
        if (workersResponse) {
            setCurrentWorkers((oldWorkers) => [...oldWorkers, ...workersResponse?.data]);
        }
    }, [workersResponse]);

    const handleChange = (e) => {
        setCurrentWorkers([]);

        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6 mb-5">
                    <h2>{data?.data?.name}</h2>
                    <p className="mb-2">
                        <img src={data?.data?.boss?.imagePath} style={{ marginRight: 10, height: 40, width: 40, borderRadius: '100%' }} alt="" />
                        <span className="text-primary">{data?.data?.boss?.name}</span>
                    </p>
                    <p className="mb-2">
                        <b>Cantidad de trabajadores: <span className="text-primary">{data?.data?.workersCount}</span> </b>
                    </p>
                    <p className="mb-2">
                        <b>Responsables: <span className="text-primary">{data?.data?.payrrolResponsiblesCount}</span> </b>
                    </p>
                </div>
                <div className="col-md-6 mb-5">
                    <div className="row justify-content-end">
                        {/* <div className="col-md-4">
                            <button className="btn btn-primary" onClick={() => setShowCreateProccessModal(true)}>
                                Iniciar Proceso <FaPlus />
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className="col-md-12">
                    <CustomTable
                        withoutGlobalActions
                        loading={loading}
                        title="Historial de procesos"
                        pages={payrrolsResponse?.meta?.last_page}
                        total={payrrolsResponse?.meta?.total}
                        values={payrrolsResponse?.data}
                        currentPage={payrrolFilters?.page}
                        collumns={CostCenterPayrrolColumns}
                        filters={payrrolFilters}
                        changePage={(page) => {
                            if (payrrolsResponse?.meta?.last_page >= page && page > 0) {
                                setFilters((oldFilters) => {
                                    return {
                                        ...oldFilters,
                                        page: page
                                    }
                                })
                            }
                        }}
                        perPage={payrrolFilters?.perPage}
                        onPerPageChange={(e) => setPayrrolFilters((oldFilters) => {
                            return {
                                ...oldFilters,
                                [e.target.name]: e.target.value
                            }
                        })}
                    />
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header px-2">
                            <div className="row w-100 align-items-center">
                                <div className="col-md-6 mb-3">
                                    <h6 className="mb-0">
                                        <FaRegUser /> Trabajadores
                                    </h6>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input type="text" className="form-control" placeholder="Buscar..." name="search" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-0 py-0">
                            <ul className="custom-scrollbar scrollbar-primary" style={{ maxHeight: '60vh', overflow: 'auto' }}>
                                {
                                    currentWorkers?.map((worker, i) => {
                                        return (

                                            <li key={i} className="mb-2 px-3">
                                                <div className="d-flex align-items-center">
                                                    <img src={worker?.imagePath || profileImg} style={{ marginRight: 10, height: 40, width: 40, borderRadius: '100%' }} alt="" />
                                                    <div>
                                                        <p className="text-primary mb-0">{worker?.name}</p>
                                                        <p className="text-dark mb-0"><b>{worker?.position?.name}</b></p>
                                                        <p className="mb-0">{worker?.documentNumber}</p>
                                                    </div>
                                                </div>

                                            </li>
                                        )
                                    })
                                }
                                {
                                    !loadingWorkers && workersResponse?.meta?.last_page > filters?.page ?
                                        <li className="text-center my-3">
                                            <button className="btn btn-xs btn-primary" onClick={() => setFilters((oldFilters) => ({ ...oldFilters, page: oldFilters?.page + 1 }))}>
                                                Cargar mas
                                            </button>
                                        </li>
                                        :
                                        null
                                }
                                {
                                    loadingWorkers &&
                                    <li className="text-center my-5">
                                        Cargando...
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CostCenterManagement;