import logo from "../images/logo.png";
import logoBlanco from "../images/logo-blanco.png";
import logoShort from "../images/logo-short.png";
import env from "./env";

const SystemInfo = {
    name: "Csi Haberes",
    description: "Sistema para la gestion y el manejo de los haberes.",
    logo: logo,
    logoBlanco: logoBlanco,
    logoShort: logoShort,
    host: env('HOST_URL'),
    api: env('API_URL'),
    systemCode: env('SYSTEM_CODE'),
    authKey: env('AUTH_KEY'),
    googleMapApiKey: env('GOOGLE_MAPS_API_KEY')
}

export default SystemInfo;