import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import useAxios from "../hooks/useAxios";
import { useFeedBack } from "../context/FeedBackContext";

const DeletePayrrolItemModal = ({ show, setShowModal, onDelete, payrrolId, costCenterId }) => {

    const { setCustomAlert } = useFeedBack();

    const [deleteItemData, setDeleteItemData] = useState({
        itemCode: '',
        payrrolWorkerIds: []
    });

    const [{ loading: loadingDelete }, deletePayrrolWorkerItems] = useAxios({ url: `/payrrol-worker-items`, method: 'DELETE' }, { useCache: false, manual: true });

    const [{ data: workersResponse }, getWorkers] = useAxios({ url: `/payrrols/${payrrolId}/workers`, params: { perPage: 1000 } }, { useCache: false });

    const [{ data: positionsResponse }, getPositions] = useAxios({ url: `/cost-centers/${costCenterId}/positions`, params: { perPage: 1000 } }, { useCache: false });

    const handleDelete = () => {
        deletePayrrolWorkerItems({
            data: {
                itemCode: deleteItemData?.itemCode,
                payrrolWorkerIds: deleteItemData?.payrrolWorkerIds
            }
        }).then(() => {
            onDelete?.();
            setShowModal(false);
            setCustomAlert({
                show: true,
                message: "Los registros se han eliminado exitosamente",
                severity: "success",
                title: 'Operación exitosa'
            });
        })
    }

    const handleChange = (e) => {
        setDeleteItemData((oldValue) => {
            return {
                ...oldValue,
                [e.target.name]: e.target.value
            }
        });
    }

    return (
        <Modal show={show} onHide={() => setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Eliminar haber
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="mb-3 col-md-12">
                        <label className="text-primary">
                            Código del haber:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={deleteItemData?.itemCode}
                            placeholder="Código del haber"
                            onChange={handleChange}
                            name="itemCode"
                        />
                        <small><span className="text-danger">Importante:</span> El sistema eliminará todos los valores del haber seleccionado y no podrán se recuperados.</small>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cerrar
                </Button>
                <Button disabled={loadingDelete} variant="primary" onClick={handleDelete}>
                    {
                        loadingDelete ?
                            <div className="spinner" style={{ width: 25, height: 25 }}>
                                <div className="double-bounce1 bg-light"></div>
                                <div className="double-bounce2 bg-light"></div>
                            </div>
                            :
                            'Guardar Cambios'
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeletePayrrolItemModal;