import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import useAxios from "../hooks/useAxios";
import clsx from "clsx";
import { useFeedBack } from "../context/FeedBackContext";

const CreatePayrrolModal = ({ show, setShowModal, onCreate }) => {

    const { setCustomAlert } = useFeedBack();

    const [createPayrrolData, setCreatePayrrolData] = useState({
        monthAndYear: '',
        confirm: '',
        costCenterIds: []
    });

    const [{ loading: loadingCreate }, storePayrrol] = useAxios({ url: `/payrrols/store/multiple`, method: 'POST' }, { useCache: false, manual: true });

    const [{ loading: loadingCostCenters, data }, getCostCenters] = useAxios({ url: `/cost-centers`, params: { perPage: 400 } }, { useCache: false });

    const handleCreate = () => {

        storePayrrol({
            data: {
                monthAndYear: createPayrrolData?.monthAndYear,
                costCenterIds: createPayrrolData?.costCenterIds
            }
        }).then(() => {
            onCreate?.();
            setShowModal(false);
            setCustomAlert({
                show: true,
                message: "Los registros se han agregado exitosamente",
                severity: "success",
                title: 'Operación exitosa'
            });
        })
    }

    const handleChange = (e) => {

        let valueToSet = e.target.value;

        if (e.target.type === 'checkbox') {
            valueToSet = createPayrrolData?.[e.target.name];
            if (valueToSet?.includes(e.target.value)) {
                valueToSet = valueToSet.filter((value) => value !== e.target.value);
            } else {
                valueToSet = [...valueToSet, e.target.value];
            }

        }

        setCreatePayrrolData((oldValue) => {
            return {
                ...oldValue,
                [e.target.name]: valueToSet
            }
        });
    }

    return (
        <Modal show={show} onHide={() => setShowModal(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Crear Nóminas Manualmente
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="mb-3 col-md-12">
                        <label htmlFor="" className="text-primary">
                            Mes y año
                        </label>
                        <input
                            type="month"
                            className="form-control"
                            value={createPayrrolData?.monthAndYear}
                            placeholder="Mes y año"
                            onChange={handleChange}
                            name="monthAndYear"
                        />
                        <br />
                        <div className="row">
                            {
                                data?.data?.map((costCenter, i) => {
                                    return (
                                        <div className="col-md-4" key={i}>
                                            <input
                                                className="form-check-input me-1"
                                                style={{ cursor: 'pointer' }}
                                                type="checkbox"
                                                value={costCenter?.id}
                                                name="costCenterIds"
                                                id={`cost-center-${costCenter?.id}`}
                                                onChange={handleChange}
                                                checked={createPayrrolData?.costCenterIds?.includes(`${costCenter?.id}`)}
                                            />
                                            <label className="form-check-label" style={{ cursor: 'pointer' }} for={`cost-center-${costCenter?.id}`}>
                                                {costCenter?.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cerrar
                </Button>
                <Button disabled={loadingCreate} variant="primary" onClick={handleCreate}>
                    {
                        loadingCreate ?
                            <div className="spinner" style={{ width: 25, height: 25 }}>
                                <div className="double-bounce1 bg-light"></div>
                                <div className="double-bounce2 bg-light"></div>
                            </div>
                            :
                            'Guardar Cambios'
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreatePayrrolModal;