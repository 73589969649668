import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import useAxios from "../hooks/useAxios";
import clsx from "clsx";
import { useFeedBack } from "../context/FeedBackContext";
import { MdDangerous } from "react-icons/md";


const CreatePayrrolItemModal = ({ show, setShowModal, onCreate, payrrolId, costCenterId }) => {

    const { setCustomAlert } = useFeedBack();

    const [createItemData, setCreateItemData] = useState({
        value: 0,
        itemCode: '',
        positionId: '',
        payrrolWorkerId: '',
        factorId: '',
        type: 'variable',
        setTo: 'worker'
    });

    const [{ loading: loadingCreate }, storePayrrolWorkerItem] = useAxios({ url: `/payrrol-worker-items`, method: 'POST' }, { useCache: false, manual: true });

    const [{ data: factorsResponse }, getFactors] = useAxios({ url: `/factors`, params: { perPage: 1000 } }, { useCache: false });

    const [{ data: workersResponse }, getWorkers] = useAxios({ url: `/payrrols/${payrrolId}/workers`, params: { perPage: 1000 } }, { useCache: false });

    const [{ data: positionsResponse }, getPositions] = useAxios({ url: `/cost-centers/${costCenterId}/positions`, params: { perPage: 1000 } }, { useCache: false });

    const handleCreate = () => {
        storePayrrolWorkerItem({
            data: {
                itemCode: createItemData?.itemCode,
                value: createItemData?.value || 0,
                positionId: createItemData?.positionId || null,
                payrrolWorkerId: createItemData?.payrrolWorkerId || null,
                factorId: createItemData?.factorId || null,
                type: createItemData?.type || 'variable',
                setTo: createItemData?.setTo,
                payrrolId,

            }
        }).then(() => {
            onCreate?.();
            setShowModal(false);
            setCustomAlert({
                show: true,
                message: "Los registros se han agregado exitosamente",
                severity: "success",
                title: 'Operación exitosa'
            });
        })
    }

    const handleChange = (e) => {
        setCreateItemData((oldValue) => {
            return {
                ...oldValue,
                [e.target.name]: e.target.value
            }
        });
    }

    return (
        <Modal show={show} onHide={() => setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Agregar haber
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="mb-3 col-md-6">
                        <label htmlFor="" className="text-primary">
                            Código del haber:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={createItemData?.itemCode}
                            placeholder="Código del haber"
                            onChange={handleChange}
                            name="itemCode"
                        />
                        <small>Sin ingresas un haber ya creado. El sistema reemplazará los valores anteriores por los nuevos.</small>
                    </div>
                    <div className="mb-3 col-md-6">
                        <label htmlFor="" className="text-primary">
                            Valor:
                        </label>
                        <CurrencyFormat
                            className="form-control"
                            style={{ minWidth: 100, display: 'block' }}
                            value={createItemData?.value}
                            isNumericString
                            decimalSeparator=","
                            thousandSeparator="."
                            onValueChange={(e) => handleChange({ target: { name: 'value', value: e.value } })}
                            placeholder="Por favor ingrese el valor"
                        />
                    </div>
                    <div className="mb-3 col-md-12">
                        <label htmlFor="" className="text-primary">
                            Factor:
                        </label>
                        <select name="factorId" className="form-control" value={createItemData?.factorId} onChange={handleChange}>
                            <option value="">Factor...</option>
                            {
                                factorsResponse?.data?.map(factor => {
                                    return (
                                        <option value={factor?.id}>
                                            {factor?.factor}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="mb-3 col-md-12">
                        <div className="form-group mb-3">
                            <p className="text-primary mb-0">
                                Configurar tipo de haber
                            </p>
                            <select name="type" value={createItemData?.type} className="form-control" onChange={handleChange}>
                                <option value="variable">Variable</option>
                                <option value="fijo">Fijo</option>
                                <option value="bloqueado">Bloqueado</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <h5 className="mb-4">
                            Agregar haber a:
                        </h5>
                        <div className="row">
                            <div className="col-md-4 text-center">
                                <h6 style={{ cursor: 'pointer' }} onClick={() => setCreateItemData(old => ({ ...old, setTo: 'worker', positionId: '' }))} className={clsx({
                                    'text-primary border-bottom border-primary': createItemData?.setTo === 'worker'
                                })}>
                                    Trabajador
                                </h6>
                            </div>
                            <div className="col-md-4 text-center">
                                <h6 style={{ cursor: 'pointer' }} onClick={() => setCreateItemData(old => ({ ...old, setTo: 'position', payrrolWorkerId: '' }))} className={clsx({
                                    'text-primary border-bottom border-primary': createItemData?.setTo === 'position'
                                })}>
                                    Cargo
                                </h6>
                            </div>
                            <div className="col-md-4 text-center">
                                <h6 style={{ cursor: 'pointer' }} onClick={() => setCreateItemData(old => ({ ...old, setTo: 'todos', payrrolWorkerId: '', positionId: '' }))} className={clsx({
                                    'text-primary border-bottom border-primary': createItemData?.setTo === 'todos'
                                })}>
                                    Todos
                                </h6>
                            </div>
                            <div className="col-md-12">
                                {
                                    createItemData?.setTo === 'worker' &&
                                    <select
                                        name="payrrolWorkerId"
                                        onChange={handleChange}
                                        value={createItemData?.payrrolWorkerId}
                                        className="form-control mt-3"
                                    >
                                        <option value="">Seleccione un trabajador</option>
                                        {
                                            workersResponse?.data?.map((worker, i) => {
                                                return (
                                                    <option value={worker?.id} key={i}>
                                                        {worker?.name} - {worker?.documentNumber}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                }
                                {
                                    createItemData?.setTo === 'position' &&
                                    <select
                                        name="positionId"
                                        onChange={handleChange}
                                        value={createItemData?.positionId}
                                        className="form-control mt-3"
                                    >
                                        <option value="">Seleccione un cargo</option>
                                        {
                                            positionsResponse?.map((position, i) => {
                                                return (
                                                    <option value={position?.id} key={i}>
                                                        {position?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                }
                                {
                                    createItemData?.setTo === 'todos' &&
                                    <div className="mt-3">
                                        <p className="m-0">
                                            El sistema le agregará este haber a todos los trabajadores en el centro de costo.
                                        </p>
                                    </div>
                                }
                                <p className="m-0 text-danger mt-3">
                                    <b><MdDangerous /> Importante:</b> Los trabajadores que ya lo tengan asignado se les reemplazará con los valores que ha ingresado en este formulario.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cerrar
                </Button>
                <Button disabled={loadingCreate} variant="primary" onClick={handleCreate}>
                    {
                        loadingCreate ?
                            <div className="spinner" style={{ width: 25, height: 25 }}>
                                <div className="double-bounce1 bg-light"></div>
                                <div className="double-bounce2 bg-light"></div>
                            </div>
                            :
                            'Guardar Cambios'
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreatePayrrolItemModal;