import { dateFine } from "../../../util/Utilities";
import ActionDropdown from "../../ActionDropdown";
import DateFormatter from "../../DateFormatter";
import TableCheck from "../TableCheck";

const IpcColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Valor',
        Component: ({ value }) => `${value?.value || '--'}`
    },
    {
        Label: () => 'Mes',
        Component: ({ value }) => `${value?.month || '--'}`
    },
    {
        Label: () => 'Año',
        Component: ({ value }) => `${value?.year || '--'}`
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    }
];

export default IpcColumns;

