import { dateFine } from "../../../util/Utilities";
import DateFormatter from "../../DateFormatter";
import FactorsActionDropdown from "../../Factors/FactorsActionDropdown";
import TableCheck from "../TableCheck";

const FactorColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'Id',
        accessor: 'id'
    },
    {
        Label: () => 'Factor',
        accessor: 'factor'
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    },
    {
        Label: () => 'Acciones',
        Component: FactorsActionDropdown
    }
];

export default FactorColumns;

