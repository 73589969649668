import { dateFine } from "../../../util/Utilities";
import CostCenterPayrrolStatusComponent from "../../CostCenterPayrrolStatusComponent";
import DateFormatter from "../../DateFormatter";
import FactorsActionDropdown from "../../Factors/FactorsActionDropdown";

const CostCenterPayrrolColumns = [
    {
        Label: () => 'Id',
        accessor: 'id'
    },
    {
        Label: () => 'Mes',
        Component: ({ value }) => `${value?.period}`
    },
    {
        Label: () => 'Año',
        Component: ({ value }) => `${value?.year}`
    },
    {
        Label: () => 'Trabajadores',
        Component: ({ value }) => `${value?.workersCount}`
    },
    {
        Label: () => 'Estado',
        Component: CostCenterPayrrolStatusComponent
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    },
    {
        Label: () => 'Acciones',
        Component: ({ value }) => <a href={`/gestionar-nomina/${value?.id}`} className="btn btn-xs btn-primary">
            Ver detalles
        </a>
    }
];

export default CostCenterPayrrolColumns;

