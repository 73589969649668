import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { FaRegUser, FaProjectDiagram } from "react-icons/fa";
import { GrUserPolice } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { useFeedBack } from "../../context/FeedBackContext";
import UserHavePermission from "../../util/userHavePermissions";
import { mainPermissions } from "../../util/MenuLinks";
import CreatePayrrolModal from "../../components/CreatePayrrolModal";
import clsx from "clsx";
import PayrrolsFiltersComponent from "../../components/Payrrols/PayrrolsFiltersComponent";
import CustomTable from "../../components/CustomTable/CustomTable";
import DashboardPayrrolColumns from "../../components/CustomTable/Columns/DashboardPayrrolColumns";
import { Accordion } from "react-bootstrap";

const Dashboard = ({ title }) => {

    const [showModal, setShowModal] = useState(false);

    const [show, setShow] = useState('cost-centers');

    const [filters, setFilters] = useState({
        name: ''
    });

    const [payrrolFilters, setPayrrolFilters] = useState({
        name: '',
        perPage: 1
    });

    const { setCurrentTitle } = useFeedBack();

    const [{ data, loading }, getDashboard] = useAxios({ url: `/dashboard`, params: filters }, { useCache: false });

    const [{ data: payrrolsData, loading: loadingPayrrols }, getPayrrols] = useAxios({ url: `/dashboard/payrrols`, params: payrrolFilters }, { useCache: false });

    const [currentCostCenters, setCurrentCostCenters] = useState([]);

    const [periodInfo, setPeriod] = useState({
        period: '',
        currentDate: '',
        currentDate2: ''
    });

    useEffect(() => {
        setCurrentTitle({
            title: `${title}`
        });
    }, [])

    useEffect(() => {
        var currentDate = new Date();
        var currentDate2 = currentDate.getDate() > 24 ? currentDate.setMonth(currentDate.getMonth() + 1) : currentDate.setMonth(currentDate.getMonth() - 1);
        var period = currentDate.getDate() > 24 ? `${format(new Date(), 'MMMM/yyyy', { locale: es })} - ${format(currentDate2, 'MMMM/yyyy', { locale: es })}` : `${format(currentDate2, 'MMMM/yyyy', { locale: es })} - ${format(new Date(), 'MMMM/yyyy', { locale: es })}`;
        setPeriod({
            currentDate: new Date(),
            currentDate2,
            period
        })
    }, [])

    return (
        <div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <h3>
                        Periodo Actual:
                    </h3>
                    <b className="text-primary" style={{ textTransform: 'capitalize' }}>{periodInfo?.period}</b>
                </div>
                <div className="col-md-6 mb-3 text-end">
                    {
                        UserHavePermission(mainPermissions.payrrols?.[1]) &&
                        <button className="btn btn-primary mb-3" onClick={() => setShowModal('create-payrrols')}>
                            Crear Nóminas
                        </button>
                    }
                    <input
                        placeholder="Buscar..."
                        type="text"
                        className="form-control"
                        name="search"
                        onChange={(e) => setFilters((oldFilters) => { return { ...oldFilters, search: e.target.value } })}
                    />
                </div>
                <div className="col-md-6 text-center mb-3">
                    <button className={clsx(["btn btn-block border"], {
                        "btn-primary": show === "cost-centers"
                    })} onClick={() => setShow("cost-centers")}>
                        Centros de costo
                    </button>
                </div>
                <div className="col-md-6 text-center mb-3">
                    <button className={clsx(["btn btn-block border"], {
                        "btn-primary": show === "payrrols"
                    })} onClick={() => setShow("payrrols")}>
                        Procesos de nómina
                    </button>
                </div>
                {
                    show === 'cost-centers' ?
                        <>
                            {
                                loading &&
                                <div className="my-5">
                                    <div className="spinner">
                                        <div className="double-bounce1 bg-primary"></div>
                                        <div className="double-bounce2 bg-primary"></div>
                                    </div>
                                </div>
                            }
                            {
                                !loading &&

                                <Accordion defaultActiveKey="0">
                                    {
                                        data?.users?.map((user, i) => {
                                            return (
                                                <Accordion.Item eventKey={`${i}`} style={{ margin: 0 }} key={i}>
                                                    <Accordion.Header style={{ border: 'none', padding: 0 }}>

                                                        <div className="row w-100">
                                                            <div className="col-md-6">
                                                                <p className="text-primary mb-0">
                                                                    Nombre:
                                                                </p>
                                                                <p className="text-black">
                                                                    {user?.bossName}
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p className="text-primary mb-0">
                                                                    Centros de costos:
                                                                </p>
                                                                <p className="text-black">
                                                                    {user?.costCentersCount}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <h4 className="text-black text-center">Listado de centros de costos</h4>
                                                        <ul class="list-group">
                                                            {
                                                                user?.costCenters?.map((costCenter, i2) => {
                                                                    return (
                                                                        <a href={`centro-de-costo/${costCenter?.id}`} class="list-group-item list-group-item-action list-group-item-primary">
                                                                            <div className="row">
                                                                                <div className="col-4">
                                                                                    <b>
                                                                                        {costCenter?.name}
                                                                                    </b>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <b>Trabajadores:</b> {costCenter?.workersCount}
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <b>Procesos de nóminas:</b> {costCenter?.payrrolsCount}
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            }
                        </>
                        :
                        null
                }

                {
                    show === 'payrrols' ?
                        <div className="col-md-12">
                            <PayrrolsFiltersComponent
                                filters={payrrolFilters}
                                setFilters={setPayrrolFilters}
                            />
                            <CustomTable
                                withoutGlobalActions
                                loading={loadingPayrrols}
                                title={"Procesos de nómina"}
                                pages={payrrolsData?.meta?.last_page}
                                total={payrrolsData?.meta?.total}
                                values={payrrolsData?.data}
                                currentPage={payrrolFilters?.page}
                                collumns={DashboardPayrrolColumns}
                                changePage={(page) => {
                                    if (payrrolsData?.meta?.last_page >= page && page > 0) {
                                        setPayrrolFilters((oldFilters) => {
                                            return {
                                                ...oldFilters,
                                                page: page
                                            }
                                        })
                                    }
                                }}
                                filters={payrrolFilters}
                                perPage={payrrolFilters?.perPage}
                                onPerPageChange={(e) => setPayrrolFilters((oldFilters) => {
                                    return {
                                        ...oldFilters,
                                        [e.target.name]: e.target.value
                                    }
                                })}
                            />
                        </div>
                        :
                        null
                }
            </div >
            <CreatePayrrolModal
                onCreate={() => getDashboard()}
                setShowModal={setShowModal}
                show={showModal === 'create-payrrols'}
            />
        </div >
    )
}

export default Dashboard;