import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import useAxios from "../hooks/useAxios";

const CostCenterPayrrolStatusComponent = ({ value }) => {

    const [{ loading, error }, updateStatus] = useAxios({ url: `/payrrols/${value?.id}/status`, method: 'PUT' }, { manual: true, useCache: false });

    const [currentStatus, setCurrentStatus] = useState(null);

    const [availableStatuses, setAvailableStatuses] = useState([]);

    useEffect(() => {
        setCurrentStatus(value?.status);
        setAvailableStatuses(value?.availableStatuses);
    }, [value])

    const handleStatus = (status) => {
        swal({
            title: "¿Estas Seguro(a)?",
            text: `¿Quieres modificar el estado del proceso a: ${status?.name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willChange) => {
            if (willChange) updateStatus({ data: { statusId: status?.id } }).then((response) => {
                console.log(response);
                setCurrentStatus(response?.data?.data?.status);
                setAvailableStatuses(response?.data?.data?.availableStatuses);
            });
        })
    }

    return (
        <Dropdown>
            <Dropdown.Toggle size="xs" variant={currentStatus?.variantColor}>
                {
                    loading ?
                        'cargando'
                        :
                        currentStatus?.name
                }
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    loading ?
                        'cargando'
                        :
                        availableStatuses?.map((status, i) => {
                            return (
                                <Dropdown.Item onClick={() => handleStatus(status)}>
                                    Cambiar a: <span style={{ color: status?.color }}>{status?.name}</span>
                                </Dropdown.Item>
                            )
                        })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default CostCenterPayrrolStatusComponent;