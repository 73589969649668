import { dateFine } from "../../../util/Utilities";
import ActionDropdown from "../../ActionDropdown";
import CostCenterPayrrolStatusComponent from "../../CostCenterPayrrolStatusComponent";
import DateFormatter from "../../DateFormatter";
import TableCheck from "../TableCheck";

const PayrrolColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'Periodo',
        accessor: 'period'
    },
    {
        Label: () => 'Año',
        accessor: 'year'
    },
    {
        Label: () => 'Tipo',
        accessor: 'type'
    },
    {
        Label: () => 'Estado',
        Component: CostCenterPayrrolStatusComponent
    },
    {
        Label: () => 'Centro de costo',
        Component: ({ value }) => `${value?.costCenter?.name || '--'}`
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    },
    {
        Label: () => 'Acciones',
        Component: ActionDropdown
    }
];

export default PayrrolColumns;

