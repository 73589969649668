import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import PayrrolWorkerItemEdit from "../../../components/Workers/PayrrolWorkerItemEdit";
import { useTheme } from "../../../context/ThemeContext";
import CreatePayrrolItemModal from "../../../components/CreatePayrrolItemModal";
import UserHavePermission from "../../../util/userHavePermissions";
import { mainPermissions } from "../../../util/MenuLinks";
import DeletePayrrolItemModal from "../../../components/DeletePayrrolItemModal";
import { FaSearch, FaUserGraduate } from "react-icons/fa";


const PayrrolDetailPageFullScreen = () => {

    const { id } = useParams();

    const { background, setHideMenu } = useTheme();

    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        itemSearch: '',
        workerSearch: '',
        positionId: '',
        page: 1,
        itemValues: {}
    })

    const [showModal, setShowModal] = useState(false);

    const [{ data, loading }, getPayrrol] = useAxios({ url: `/payrrols/${id}/render-detail`, params: filters }, { useCache: false });

    const [{ data: positions, loading: loadingPositions }, getPositions] = useAxios({ params: { perPage: 100 } }, { manual: true, useCache: false });

    useEffect(() => {
        setHideMenu(true);
    }, [])

    useEffect(() => {
        if (data?.costCenterId) getPositions({ url: `/cost-centers/${data?.costCenterId}/positions` })
    }, [data])

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const handleItemValue = (itemCode, itemValue) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                itemValues: {
                    ...oldFilters?.itemValues,
                    [itemCode]: itemValue
                }
            }
        })
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="row w-100 align-items-center">
                        <div className="col-md-4">
                            <h4>
                                Haberes
                            </h4>
                            <a href="#" onClick={() => navigate(-1)}>
                                Volver
                            </a>
                        </div>
                        <div className="col-md-8" style={{ marginLeft: 'auto' }}>
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    {
                                        data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems) ?
                                            <Dropdown>
                                                <Dropdown.Toggle variant="primary" size="xs" style={{ maxWidth: '100%' }}>
                                                    Gestionar Haberes
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {
                                                        data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems[0]) ?
                                                            <Dropdown.Item onClick={() => setShowModal('create-items')} className="text-primary">
                                                                Agregar haber
                                                            </Dropdown.Item>
                                                            :
                                                            null
                                                    }
                                                    {/* {
                                                data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems[1]) ?
                                                    <Dropdown.Item onClick={() => setShowModal('delete-items')} className="text-danger">
                                                        Eliminar haber
                                                    </Dropdown.Item>
                                                    :
                                                    null
                                            } */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            :
                                            null
                                    }
                                </div>
                                <div className="col-md-3">
                                    <p className="text-primary m-0">
                                        <FaUserGraduate /> Filtrar por cargo:
                                    </p>
                                    <select name="positionId" value={filters?.positionId} className="form-control border-primary" onChange={handleChange}>
                                        <option value="">
                                            Seleccione un Cargo...
                                        </option>
                                        {
                                            positions?.map((position, i) => {
                                                return (
                                                    <option value={position?.id} key={i}>
                                                        {position?.name} ({position?.workersCount})
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <p className="text-primary m-0">
                                        <FaSearch style={{ fontWeight: 'bold' }} /> Buscar trabajador:
                                    </p>
                                    <input
                                        type="text"
                                        className="form-control border-primary"
                                        onChange={handleChange}
                                        placeholder="Nombre, Cargo, Rut del trabajador..."
                                        name="workerSearch"
                                        value={filters?.workerSearch}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <p className="text-primary m-0">
                                        <FaSearch style={{ fontWeight: 'bold' }} /> Buscar haberes:
                                    </p>
                                    <input
                                        type="text"
                                        className="form-control border-primary"
                                        onChange={handleChange}
                                        placeholder="Código, Descripción del haber..."
                                        name="itemSearch"
                                        value={filters?.itemSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="card-body">
                    {
                        loading ?
                            <div className="spinner">
                                <div className="double-bounce1 bg-primary"></div>
                                <div className="double-bounce2 bg-primary"></div>
                            </div>
                            :
                            <div className="custom-scrollbar custom-horizontal-scrollbar scrollbar-primary" style={{ height: 400, overflowY: 'auto' }}>
                                <table>
                                    <thead>
                                        <tr style={{ zIndex: 10, left: 0, top: 0 }}>
                                            <th className="border-end border-bottom p-2" style={{
                                                width: 280,
                                                position: 'sticky',
                                                top: 0,
                                                left: 0,
                                                zIndex: 10,
                                                background: background?.value === 'dark' ? '#212130' : 'white',
                                            }}>
                                                <strong>Nombre del trabajador</strong>
                                            </th>
                                            {
                                                data?.items?.map((item, i) => {
                                                    return (
                                                        <th key={i} style={{
                                                            zIndex: 9,
                                                            fontSize: 10,
                                                            width: 200,
                                                            position: 'sticky',
                                                            top: 0,
                                                            background: background?.value === 'dark' ? '#212130' : 'white',
                                                        }}
                                                            className="text-dark text-center border-end border-bottom p-2"
                                                        >
                                                            <small className="text-primary">
                                                                ({item?.code})
                                                            </small>
                                                            <br />
                                                            <strong>{item?.description}</strong>
                                                            {
                                                                item?.item_values && item?.item_values.length > 0 ?
                                                                    <div>
                                                                        <select
                                                                            className="rounded"
                                                                            onChange={(e) => handleItemValue(item?.code, e.target.value)}
                                                                            value={filters?.itemValues?.[item?.code]}
                                                                        >
                                                                            <option value="">Seleccione una opción</option>
                                                                            <option value="sin valor">Sin valor</option>
                                                                            {
                                                                                item?.item_values?.map((itemOption, i) => {
                                                                                    return (
                                                                                        <option value={itemOption} key={i}>
                                                                                            {itemOption?.toLocaleString()}
                                                                                        </option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    <p>
                                                                        --
                                                                    </p>
                                                            }
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.workers?.map((worker, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td style={{ width: 280, fontSize: 10, zIndex: 8, left: 0, position: 'sticky', background: background?.value === 'dark' ? '#212130' : 'white' }} className="text-dark border-end border-bottom p-2">
                                                        <div style={{ width: 200 }}>
                                                            <strong>{worker?.name}</strong>
                                                            <br />
                                                            <small className="text-primary">
                                                                {worker?.position_name}
                                                            </small>
                                                            <br />
                                                            <strong>{worker?.document_number || '--'}</strong>
                                                        </div>
                                                    </td>
                                                    {
                                                        worker?.items?.map((itemValue, i) => {
                                                            return (
                                                                <td key={i} style={{ fontSize: 10, width: 200 }} className="border-end border-bottom p-2">
                                                                    <PayrrolWorkerItemEdit
                                                                        itemValue={itemValue}
                                                                        payrrolWorkerItemId={itemValue?.payrrolWorkerItemId}
                                                                        value={itemValue?.value}
                                                                        type={itemValue?.type}
                                                                        factor={itemValue?.factor}
                                                                        note={itemValue?.note}
                                                                        defaultValue={itemValue?.default_value}
                                                                        canUpdateItems={data?.canUpdate}
                                                                    />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
            {
                data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems[0]) ?
                    <CreatePayrrolItemModal
                        show={showModal === 'create-items'}
                        setShowModal={setShowModal}
                        onCreate={() => getPayrrol()}
                        payrrolId={id}
                        costCenterId={data?.costCenterId}
                    />
                    :
                    null
            }
            {
                data?.canUpdate && UserHavePermission(mainPermissions?.payrrolWorkerItems[1]) ?
                    <DeletePayrrolItemModal
                        show={showModal === 'delete-items'}
                        setShowModal={setShowModal}
                        onDelete={() => getPayrrol()}
                        payrrolId={id}
                        costCenterId={data?.costCenterId}
                    />
                    :
                    null
            }
        </div >
    )
}

export default PayrrolDetailPageFullScreen;