import { dateFine } from "../../../util/Utilities";
import ActionDropdown from "../../ActionDropdown";
import DateFormatter from "../../DateFormatter";
import TableCheck from "../TableCheck";

const PositionColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Nombre',
        accessor: 'name'
    },
    {
        Label: () => 'Habares cargados',
        accessor: 'itemsCount'
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    },
    {
        Label: () => 'Acciones',
        Component: ActionDropdown
    }
];

export default PositionColumns;

