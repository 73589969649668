import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import update from 'immutability-helper';
import { useFeedBack } from "../../context/FeedBackContext";

const CostCenterNotificationEmailsComponent = ({ costCenterId }) => {

    const { setCustomAlert } = useFeedBack();

    const [{ data: costCenterResponse }, getCostCenter] = useAxios({ url: `/cost-centers/${costCenterId}` }, { useCache: false })

    const [{ data: storeCostCenterEmailsData, loading: storeLoading }, storeCostCenterEmails] = useAxios({ method: 'POST', url: `/cost-centers/${costCenterId}/cost-center-notification-emails` }, { manual: true, useCache: false })

    const [emails, setEmails] = useState([]);

    useEffect(() => {
        if (costCenterResponse) {
            setEmails(costCenterResponse?.data?.emails);
        }
    }, [costCenterResponse])

    const handleEmailChange = (e, i) => {
        const newEmailsValues = update(emails, { [i]: { $set: e.target.value } });

        setEmails(newEmailsValues);
    }

    const handleAddEmail = () => {
        setEmails((oldEmails) => {
            return [...oldEmails, '']
        })
    }

    const handleDelete = (i) => {
        const newEmailsValues = update(emails, { $splice: [[i, 1]] });
        setEmails(newEmailsValues);
    }

    const handleSubmit = (e) => {
        storeCostCenterEmails({ data: { emails } }).then(() => {
            setCustomAlert({ show: true, message: "Los datos se han guardado exitosamente.", severity: "success", title: 'Operación exitosa.' });
        });
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="card-title">
                    Correos para la notificación de comentarios
                </div>
            </div>
            <div className="card-body">
                {
                    emails?.map((email, i) => {
                        return (
                            <div class="input-group mb-3">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Introduce el email"
                                    value={email}
                                    onChange={(e) => handleEmailChange(e, i)}
                                />
                                <button class="btn btn-outline-danger" type="button" onClick={() => handleDelete(i)}>
                                    Eliminar
                                </button>
                            </div>
                        )
                    })
                }
                <div className="text-center">
                    <button type="button" className="btn btn-primary btn-xs" onClick={handleAddEmail}>
                        Agregar correo
                    </button>
                </div>
            </div>
            <div className="card-footer text-center">
                <button disabled={storeLoading} className="btn btn-primary w-100" onClick={handleSubmit}>
                    {
                        storeLoading ?
                            'Cargando'
                            :
                            'Actualizar'
                    }
                </button>
            </div>
        </div>
    )
}

export default CostCenterNotificationEmailsComponent;