import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format"
import useAxios from "../../hooks/useAxios";
import clsx from "clsx";
import { AiFillExclamationCircle } from "react-icons/ai";
import { monthNames } from "../../util/consts";

const UpdateCostCenterPositionItemInput = ({ defaultValue, costCenterId, itemCode, positionId, factors, url }) => {

    const [currentValue, setCurrentValue] = useState({
        value: 0,
        applyIpc: 'no',
        factorId: '',
        applyMonths: [],
        applyIpcMonths: [],
        type: 'variable'
    });

    const [hasError, setHasError] = useState(false);

    const [{ loading: loadingUpdate, error }, updateCurrentItem] = useAxios({ url: url, method: 'PUT' }, { useCache: false, manual: true });

    useEffect(() => {
        if (error) {
            setHasError(true);
        }
    }, [error])

    useEffect(() => {
        if (defaultValue) setCurrentValue((oldValue) => {
            return {
                ...oldValue,
                ...defaultValue
            }
        });
    }, [defaultValue])

    const handleChange = (e) => {

        setHasError(false);

        var dataToSet = {
            ...currentValue
        };



        if (e.target.type === 'checkbox') {
            if (dataToSet[e.target.name].includes(Number(e.target.value))) {
                dataToSet[e.target.name] = dataToSet[e.target.name].filter(value => value !== Number(e.target.value));
            } else {
                dataToSet[e.target.name] = [...dataToSet[e.target.name], Number(e.target.value)];
            }
        } else {
            dataToSet[e.target.name] = e.target.value
        }

        console.log(dataToSet);

        updateCurrentItem({
            data: {
                ...dataToSet,
                itemCode
            }
        });

        setCurrentValue(dataToSet);
    }

    return (
        <div className="position-relative">
            <div className="position-absolute" style={{ right: 0, top: 0 }}>
                {
                    loadingUpdate &&
                    <div className="spinner" style={{ height: 20, width: 20, marginLeft: 10 }}>
                        <div className="double-bounce1 bg-primary"></div>
                        <div className="double-bounce2 bg-primary"></div>
                    </div>
                }

                {
                    !loadingUpdate && hasError ?
                        <AiFillExclamationCircle className="text-danger" style={{ marginLeft: 10, fontSize: 15 }} />
                        :
                        null
                }
            </div>
            <div className="row align-items-end">
                <div className="mb-3 col-md-4">
                    <div className="d-flex align-items-center">
                        <CurrencyFormat
                            className={clsx(["form-control"], {
                                'border border-danger': hasError
                            })}
                            style={{ minWidth: 100, display: 'block' }}
                            value={currentValue?.value}
                            isNumericString
                            decimalSeparator=","
                            thousandSeparator="."
                            onValueChange={(e) => handleChange({ target: { name: 'value', value: e.value } })}
                            placeholder="Por favor ingrese el valor"
                        />
                    </div>
                    <br />
                    <select name="factorId" className="form-control" value={currentValue?.factorId} onChange={handleChange}>
                        <option value="">Factor...</option>
                        {
                            factors?.map(factor => {
                                return (
                                    <option value={factor?.id}>
                                        {factor?.factor}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="mb-3 col-md-8">
                    <div>
                        <p className="text-primary mb-0">
                            Aplicar solo los meses
                        </p>
                        <small className="d-block">Al dejar las casillas en blanco el sistema tomará todos los meses por defecto.</small>
                        <br />
                        <div className="row align-items-center">
                            {
                                monthNames?.map((month, key) => {
                                    return (
                                        <label className="mb-3 mb-md-1 col-md-3 d-flex" style={{ cursor: 'pointer' }}>
                                            {month?.name}
                                            <input
                                                checked={currentValue?.applyMonths?.includes(key + 1)}
                                                type="checkbox"
                                                onChange={handleChange}
                                                value={key + 1}
                                                name="applyMonths"
                                                className="ml-auto"
                                                style={{ marginLeft: 'auto' }}
                                            />
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="mb-3 col-md-4">
                    <div className="form-group mb-3">
                        <p className="text-primary mb-0">
                            Configurar tipo de haber
                        </p>
                        <select name="type" value={currentValue?.type} className="form-control" onChange={handleChange}>
                            <option value="variable">Variable</option>
                            <option value="fijo">Fijo</option>
                            <option value="bloqueado">Bloqueado</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <p className="text-primary mb-0">
                            ¿Aplica IPC?
                        </p>
                        <select name="applyIpc" value={currentValue?.applyIpc} className="form-control" onChange={handleChange}>
                            <option value="no">no</option>
                            <option value="si">si</option>
                        </select>
                    </div>
                </div>
                <div className="mb-3 col-md-8">
                    {
                        currentValue?.applyIpc === 'si' ?
                            <div className="animate__animated animate__fadeInRight">
                                <p className="text-primary mb-0">
                                    Aplicar IPC en los meses:
                                </p>
                                <small className="d-block">Al dejar las casillas en blanco el sistema tomará todos los meses por defecto.</small>
                                <br />
                                <div className="row align-items-center">
                                    {
                                        monthNames?.map((month, key) => {
                                            return (
                                                <label className="mb-3 mb-md-0 col-md-3 d-flex">
                                                    {
                                                        month?.name
                                                    }
                                                    <input
                                                        checked={currentValue?.applyIpcMonths?.includes(key + 1)}
                                                        type="checkbox"
                                                        value={key + 1}
                                                        name="applyIpcMonths"
                                                        className="ml-auto"
                                                        onChange={handleChange}
                                                        style={{ marginLeft: 'auto' }}
                                                    />
                                                </label>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            :
                            <div className="text-center">
                                --
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default UpdateCostCenterPositionItemInput