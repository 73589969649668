import { Button, Modal } from "react-bootstrap"
import useAxios from "../hooks/useAxios"
import { useEffect, useState } from "react";
import { useFeedBack } from "../context/FeedBackContext";

const AddPayrrolWorkerModal = ({
    show,
    setShowModal,
    onUpdate,
    payrrolId,
}) => {

    const { setCustomAlert } = useFeedBack();

    const [createPayrrolWorkerData, setCreatePayrrolWorkerData] = useState({
        payrrolId,
        userIds: []
    });

    const [{ data, loading }, getAvailableWorkers] = useAxios({ url: `/payrrol-workers/find-available-workers`, params: { payrrolId } }, { manual: true, useCache: false });

    const [{ loading: loadingUpdate }, storePayrrolWorkers] = useAxios({ url: `/payrrol-workers`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (show) getAvailableWorkers();
    }, [show])

    const handleChange = (e) => {

        let valueToSet = e.target.value;

        if (e.target.type === 'checkbox') {
            valueToSet = createPayrrolWorkerData?.[e.target.name];
            if (valueToSet?.includes(e.target.value)) {
                valueToSet = valueToSet.filter((value) => value !== e.target.value);
            } else {
                valueToSet = [...valueToSet, e.target.value];
            }
        }

        setCreatePayrrolWorkerData((oldValue) => {
            return {
                ...oldValue,
                [e.target.name]: valueToSet
            }
        });
    }

    const handleSubmit = (e) => {
        storePayrrolWorkers({
            data: createPayrrolWorkerData
        }).then(() => {
            setShowModal(false);
            onUpdate?.();
            setCustomAlert({ show: true, message: "Los cambios se han agregado de forma exitosa.", severity: "success", title: 'Operación exitosa' });
        })
    }


    return (
        <Modal show={show} onHide={() => setShowModal(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Agregar trabajadores
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 style={{ color: 'black' }} className="mb-3">
                    Por favor seleccione los trabajadores que desea agregar:
                </h4>
                <div className="row">
                    {
                        data?.map((user, i) => {
                            return (
                                <div className="col-md-3" key={i}>
                                    <input
                                        className="form-check-input me-1"
                                        style={{ cursor: 'pointer' }}
                                        type="checkbox"
                                        value={user?.id}
                                        name="userIds"
                                        id={`user-${user?.id}`}
                                        onChange={handleChange}
                                        checked={createPayrrolWorkerData?.userIds?.includes(`${user?.id}`)}
                                    />
                                    <label className="form-check-label text-black" style={{ cursor: 'pointer' }} for={`user-${user?.id}`}>
                                        {user?.name}
                                        <br />
                                        {user?.document_number}
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cerrar
                </Button>
                <Button disabled={loadingUpdate} variant="primary" onClick={handleSubmit}>
                    {
                        loadingUpdate ?
                            <div className="spinner" style={{ width: 25, height: 25 }}>
                                <div className="double-bounce1 bg-light"></div>
                                <div className="double-bounce2 bg-light"></div>
                            </div>
                            :
                            'Guardar Cambios'
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddPayrrolWorkerModal;