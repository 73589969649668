import { mainPermissions } from "../../util/MenuLinks";
import UserHavePermission from "../../util/userHavePermissions";


const FactorsModuleActionsComponent = ({ path }) => {

    return (
        <div className="text-end">
            {
                UserHavePermission(mainPermissions?.factors?.[1]) &&
                <>
                    <a href={path + '/crear'} className="btn mx-1 btn-primary">
                        Crear Factor
                    </a>
                    <a href={path + '/importar'} className="btn mx-1 btn-success">
                        Importar Factores
                    </a>
                </>
            }
        </div>
    )
}

export default FactorsModuleActionsComponent;