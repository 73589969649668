const PositionFiltersComponent = ({ filters, setFilters }) => {

    const hadleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6  p-3">
                    <div className="form-group">
                        <div className="card p-3">
                            <label className="form-label">Id</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Id"
                                value={filters?.id}
                                onChange={hadleChange}
                                name="id" />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 p-3">
                    <div className="card p-3">
                        <div className="form-group">
                            <label className="form-label">Nombre</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Ingrese el nombre"
                                value={filters?.name}
                                onChange={hadleChange}
                                name="name" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PositionFiltersComponent;