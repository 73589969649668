import useAxios from "../../hooks/useAxios";


const UsersFiltersComponent = ({ filters, setFilters }) => {

    const [{ data: costCentersResponse, loading: loadingCostCenters }, getCostCenters] = useAxios({ url: `/cost-centers`, params: { perPage: 1000 } }, { useCache: false });

    const [{ data: positionsResponse, loading: loadingpositions }, getPositions] = useAxios({ url: `/positions`, params: { perPage: 1000 } }, { useCache: false });

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="card p-3">
                        <div className="form-group">
                            <label className="form-label">Nombre</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Nombre"
                                value={filters?.name}
                                onChange={handleChange}
                                name="name"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card p-3">
                        <div className="form-group">
                            <label className="form-label">Rut</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Rut"
                                value={filters?.documentNumber}
                                onChange={handleChange}
                                name="documentNumber"
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card p-3">
                        <div className="form-group">
                            <label className="form-label">Cargo</label>
                            <select
                                name="positionId"
                                value={filters?.positionId}
                                className="form-control"
                                onChange={handleChange}
                            >
                                <option value="">Seleccione un cargo</option>
                                {
                                    positionsResponse?.data?.map((position, i) => {
                                        return (
                                            <option value={position?.id} key={i}>
                                                {position?.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card p-3">
                        <div className="form-group">
                            <label className="form-label">Centro de costo</label>
                            <select
                                name="costCenterId"
                                value={filters?.costCenterId}
                                className="form-control"
                                onChange={handleChange}
                            >
                                <option value="">Seleccione un centro de costo</option>
                                {
                                    costCentersResponse?.data?.map((costCenter, i) => {
                                        return (
                                            <option value={costCenter?.id} key={i}>
                                                {costCenter?.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersFiltersComponent;