import { mainPermissions } from "../../util/MenuLinks";
import UserHavePermission from "../../util/userHavePermissions";


const UsersModuleActionsComponent = ({ path }) => {

    return (
        <div className="text-end">
            {
                UserHavePermission(mainPermissions?.users?.[1]) &&
                <a href={path + '/importar-haberes'} className="btn btn-success">
                    Importar Configuración De los Haberes
                </a>
            }
        </div>
    )
}

export default UsersModuleActionsComponent;