import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import { dateFine } from "../../../util/Utilities";
import DateFormatter from "../../DateFormatter";

const CostCenterColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Código Payroll',
        Component: ({ value }) => value?.payrrolId || '--'
    },
    {
        Label: () => 'Nombre',
        Component: ({ value }) => <p style={{ textTransform: 'uppercase' }}> {value?.name || '--'} </p>
    },
    {
        Label: () => 'Jefe',
        Component: ({ value }) => <p style={{ textTransform: 'uppercase' }}> {value?.boss?.name || '--'} </p>
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => value ? <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" /> : '--'
    },
    {
        Label: () => 'Acciones',
        Component: ActionDropdown
    }
];

export default CostCenterColumns;

