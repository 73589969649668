import swal from "sweetalert";
import useAxios from "../hooks/useAxios";

const DeletePayrrolWorkerButton = ({ payrrolWorker, onDelete }) => {

    const [{ loading }, deletePayrrolWorker] = useAxios({ url: `payrrol-workers/${payrrolWorker?.id}`, method: 'DELETE' }, { manual: true, useCache: false });

    const handleDelete = () => {
        swal({
            title: "¿Estas Seguro(a)?",
            text: "¿Quieres eliminar el trabajador del proceso de nómina?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deletePayrrolWorker?.().then(() => {
                    onDelete?.();
                });
            }
        })
    }

    return (
        <button disabled={loading} onClick={handleDelete} className="d-block text-danger" style={{ background: 'transparent', border: 'none' }}>
            {
                loading ?
                    'cargando'
                    :
                    'eliminar'
            }
        </button>
    )
}

export default DeletePayrrolWorkerButton;