import useAxios from "../../hooks/useAxios";
import { periodTypeDescription } from "../../util/consts";

const PayrrolsFiltersComponent = ({ filters, setFilters }) => {

    const [{ data: statusesResponse, loading: loadingStatuses }, getStatuses] = useAxios({ url: `/statuses` }, { useCache: false });

    const [{ data: costCentersResponse, loading: loadingCostCenters }, getCostCenters] = useAxios({ url: `/cost-centers`, params: { perPage: 1000 } }, { useCache: false });

    const hadleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-4 p-3">
                    <div className="form-group">
                        <div className="card p-3">
                            <label className="form-label">Periodo</label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Periodo"
                                value={filters?.period}
                                onChange={hadleChange}
                                name="period"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 p-3">
                    <div className="form-group">
                        <div className="card p-3">
                            <label className="form-label">Año</label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Año"
                                value={filters?.year}
                                onChange={hadleChange}
                                name="year"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 p-3">
                    <div className="form-group">
                        <div className="card p-3">
                            <label className="form-label">Tipo</label>
                            <select name="type" value={filters?.type} className="form-control" onChange={hadleChange}>
                                <option value="">Seleccione una opción</option>
                                {
                                    Object.keys(periodTypeDescription).map((value, key) => {
                                        return (
                                            <option value={value} key={value}>{value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 p-3">
                    <div className="form-group">
                        <div className="card p-3">
                            <label className="form-label">Estado</label>
                            <select name="statusId" value={filters?.statusId} className="form-control" onChange={hadleChange}>
                                <option value="">Seleccione un estado</option>
                                {
                                    statusesResponse?.data?.map((status, i) => {
                                        return (
                                            <option value={status?.id}>
                                                {status?.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 p-3">
                    <div className="form-group">
                        <div className="card p-3">
                            <label className="form-label">Centro de costo</label>
                            <select name="costCenterId" value={filters?.costCenterId} className="form-control" onChange={hadleChange}>
                                <option value="">Seleccione un centro de costo</option>
                                {
                                    costCentersResponse?.data?.map((costCenter, i) => {
                                        return (
                                            <option value={costCenter?.id}>
                                                {costCenter?.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayrrolsFiltersComponent;