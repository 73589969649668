import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import { useNavigate, useParams } from "react-router-dom";

const FactorsUpdate = ({ path, endPoint }) => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { setCustomAlert } = useFeedBack();

    const [data, setData] = useState({
        factor: ''
    });

    const [{ data: record }, getRecord] = useAxios({ url: `${endPoint}/${id}` }, { useCache: false });

    const [{ data: response }, updateFactor] = useAxios({ url: `${endPoint}/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (record) {
            setData((oldData) => {
                return {
                    ...oldData,
                    factor: record?.data?.factor
                }
            });
        }
    }, [record])

    useEffect(() => {
        if (response) {
            setCustomAlert({
                show: true,
                severity: 'success',
                title: 'Operación Exitosa',
                message: 'El registro fue actualizado exitosamente.'
            });
            navigate('/factores');
        }
    }, [response])

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (e) => {
        e?.preventDefault();

        updateFactor({ data })
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="card">
                    <div className="card-header">
                        Editar Factores
                    </div>
                    <div className="card-body">
                        <dir className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="text-primary">
                                        Factor
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={data?.factor}
                                        name="factor"
                                        placeholder="Ingrese el factor"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </dir>
                    </div>
                    <div className="card-footer text-end">
                        <a href={'/factores'} className="btn btn-danger" style={{ marginRight: 10 }}>
                            Volver
                        </a>
                        <button className="btn btn-primary">
                            Actualizar
                        </button>
                    </div>
                </div>
            </form>
        </div >
    )
}
export default FactorsUpdate