import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import { dateFine } from "../../../util/Utilities";
import DateFormatter from "../../DateFormatter";

const ItemColumns = [
    {
        Label: TableCheck,
        Component: TableCheck

    },
    {
        Label: () => 'Codigo',
        Component: ({ value }) => value?.code || '--'
    },
    {
        Label: () => 'Descripción',
        accessor: 'description'
    },
    {
        Label: () => 'Tipo',
        Component: ({ value }) => value?.type || '--'
    },
    {
        Label: () => 'Orden',
        Component: ({ value }) => value?.order || '--'
    },
    {
        Label: () => 'VO',
        Component: ({ value }) => value?.vo || '--'
    },
    {
        Label: () => 'Propor',
        Component: ({ value }) => value?.propor || '--'
    },
    {
        Label: () => 'Coform',
        Component: ({ value }) => value?.coform || '--'
    },
    {
        Label: () => 'Acumus',
        Component: ({ value }) => value?.acumus || '--'
    },
    {
        Label: () => 'Dato',
        Component: ({ value }) => value?.dato || '--'
    },
    {
        Label: () => 'Retención',
        Component: ({ value }) => value?.retention || '--'
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    },
    {
        Label: () => 'Acciones',
        Component: ActionDropdown
    }
];

export default ItemColumns;

