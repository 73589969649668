import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useEffect, useState } from "react";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import { Dropdown, ListGroup } from "react-bootstrap";
import { monthNames } from "../../../util/consts";
import RenderStatus from "../../../components/RenderStatus";
import ChangePayrrolStatusComponent from "../../../components/Payrrols/ChangePayrrolStatusComponent";
import DateFormatter from "../../../components/DateFormatter";
import { dateFine } from "../../../util/Utilities";


const PayrrolManagement = () => {

    const navigate = useNavigate();

    const { id } = useParams();

    const [currentPayrrol, setCurrentPayrrol] = useState(null);

    const [{ data, loading }, getPayrrol] = useAxios({ url: `/payrrols/${id}` }, { useCache: false });

    const [{ loading: loadingExport }, getExport] = useAxios(null, { manual: true, useCache: false });

    useEffect(() => {
        if (data) {
            setCurrentPayrrol(data?.data);
            console.log(data);
        }
    }, [data])

    const handleExport = (format) => {
        getExport({ url: `/payrrols/${id}/export/${format}`, responseType: 'blob' }).then((response, i) => {
            handleBlobResponse(response?.data);
        });
    }

    const handleChangePayrrol = () => {
        getPayrrol();
    }

    const handleBlobResponse = (blobResponse) => {
        const fileBlobUrl = URL.createObjectURL(blobResponse);
        const aToDownload = document.getElementById('downloadLink');
        /* aToDownload.download = fileName; */
        aToDownload.href = fileBlobUrl;
        aToDownload?.click();
        window.URL.revokeObjectURL(fileBlobUrl);
    }

    return (
        <div>
            <div className="text-end mb-3 d-flex justify-content-end align-items-center w-100">
                <button onClick={() => navigate(-1)} className="btn btn-primary me-2" style={{ marginLeft: 'auto', width: 'fit-content' }}>
                    Volver Atras
                </button>
                <a href={`/gestionar-nomina/detalle/${id}`} className="btn btn-primary me-2">
                    Ver en detalle
                </a>
                <Dropdown autoClose="outside">
                    <Dropdown.Toggle variant='primary'>
                        Exportar
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: '50vh', overflowY: 'auto' }} className="custom-scrollbar scrollbar-primary">
                        {
                            loadingExport ?
                                <div className="spinner">
                                    <div className="double-bounce1 bg-primary"></div>
                                    <div className="double-bounce2 bg-primary"></div>
                                </div>
                                :
                                <>
                                    <Dropdown.Item autoClose={false} onClick={() => handleExport('excel')} className="text-success">
                                        <FaRegFileExcel />
                                        Excel Resumen
                                    </Dropdown.Item>
                                    <Dropdown.Item autoClose={false} onClick={() => handleExport('excel-items')} className="text-success">
                                        <FaRegFileExcel />
                                        Excel Haberes
                                    </Dropdown.Item>
                                    <Dropdown.Item autoClose={false} onClick={() => handleExport('pdf')} className="text-danger">
                                        <FaRegFilePdf />
                                        PDF Resumen
                                    </Dropdown.Item>
                                </>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className="card">
                <div className="card-header d-md-flex d-block">
                    <div>
                        <h3 className="m-0">
                            {currentPayrrol?.costCenter?.name}
                        </h3>
                        {
                            currentPayrrol?.period && currentPayrrol?.year ?
                                <p className="animate__animated animate__fadeInLeft">Nómina del mes: <span className="text-primary">{monthNames?.[currentPayrrol?.period - 1]?.name}/{currentPayrrol?.year}</span></p>
                                :
                                null
                        }
                    </div>
                    <h4 className="text-md-end m-0">
                        <div className="d-flex align-items-center">
                            <b style={{ marginRight: 8 }}>Periodo del proceso:</b>
                            <div className={`btn btn-xs btn-${currentPayrrol?.status?.variantColor || 'light'}`}>
                                {
                                    currentPayrrol ?
                                        `${currentPayrrol?.period}/${currentPayrrol?.year}`
                                        :
                                        'Sin datos'
                                }
                            </div>
                        </div>
                        <p className="m-0">
                            <b>Estado del proceso:</b>
                            <span style={{ color: currentPayrrol?.status?.color }}>
                                {currentPayrrol?.status?.name}
                            </span>
                        </p>
                    </h4>
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-header">
                    <h5>
                        Progreso:
                    </h5>
                    <div className="col-md-4 d-flex align-items-center justify-content-end">
                        <ChangePayrrolStatusComponent
                            payrrol={currentPayrrol}
                            onChangeStatus={handleChangePayrrol}
                        />
                    </div>
                </div>
                <div className="card-body">
                    <RenderStatus status={currentPayrrol?.status} />
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-header">
                    <h5>
                        Historial de estados:
                    </h5>
                </div>
                <div className="card-body">
                    <ul>
                        <ListGroup>
                            {
                                currentPayrrol?.statuses?.map((status, i) => {
                                    return (
                                        <ListGroup.Item key={i} style={{ borderBottomColor: status?.status?.color }}>
                                            <h6 className={`text-${status?.status?.variantColor} mb-0`}>
                                                {status?.status?.name}
                                            </h6>
                                            <small>
                                                {status?.createdAt}
                                            </small>
                                            <p>
                                                El usuario: <b>{status?.user?.name}</b> ha cambiado el estado del proceso de nómina.
                                            </p>
                                        </ListGroup.Item>
                                    )
                                })
                            }
                        </ListGroup>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PayrrolManagement;