import { useEffect, useState } from 'react';
import useAxios from './useAxios';

const useItems = ({ options, axiosConfig } = {}) => {
  const [{ data, error, loading }, getItems] = useAxios({ url: '/items', ...axiosConfig }, options);

  const [items, setItems] = useState([]);

  const [total, setTotal] = useState(0);

  const [size, setSize] = useState(0);

  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    if (data) {
      setItems(data.data);
      setTotal(data?.meta?.total);
      setSize(data?.meta?.per_page);
      setNumberOfPages(data.meta?.last_page);
    }

  }, [data, loading, error]);

  return [{ items, total, numberOfPages, size, error, loading }, getItems];
};

export default useItems;
